
let parallax = () => {
  let navbar = document.getElementById('nav');
  let theme = document.getElementById('theme')

  let title = document.getElementById('title-text');
  let subTitle = document.getElementById('subtitle-text');

  let aboutSection = document.getElementById('about-sec');
  let about = document.getElementById('about');
  let aboutP = document.getElementById('about-p');

  let leftSide = document.getElementById('left');
  let leftSide2 = document.getElementById('left-2');
  let leftSide3 = document.getElementById('left-3');
  let leftSide4 = document.getElementById('left-4');

  let moon = document.getElementById('moon');
  let moon2 = document.getElementById('moon2');
  let moon3 = document.getElementById('moon3');

  let viking2 = document.getElementById('viking2');
  let viking3 = document.getElementById('viking3');
  let viking4 = document.getElementById('viking4');

  let vino = document.getElementById('vino');
  let vino2 = document.getElementById('vino2');
  let vino3 = document.getElementById('vino3');

  let tesla = document.getElementById('tesla');
  let tesla2 = document.getElementById('tesla2');
  let tesla3 = document.getElementById('tesla3');
  let tesla4 = document.getElementById('tesla4');

  let skill = document.getElementById('skill');
  // let skill2 = document.getElementById('skill-2');
  // let skill3 = document.getElementById('skill-3');
  // let skill4 = document.getElementById('skill-4');
  // let skill5 = document.getElementById('skill-5');

  // let skill6 = document.getElementById('skill-6');
  // // let skill7 = document.getElementById('skill-7');
  // // let skill8 = document.getElementById('skill-8');
  let skill9 = document.getElementById('skill-9');

  let footer = document.getElementById('footer')

  window.addEventListener('scroll', function () {
    let value = window.scrollY;

    title.style.marginRight = value * 2 + 'px';
    subTitle.style.marginLeft = value * 2 + 'px';
    theme.style.marginTop = value * -0.4 + 'px'

    let rect = aboutSection.getBoundingClientRect();
    let rect2 = navbar.getBoundingClientRect();

    if (rect.bottom > rect2.bottom) {
      about.style.display = 'block'
      aboutP.style.display = 'block'
      about.style.marginLeft = value * 0.5 + 'px';
      aboutP.style.marginRight = value * 0.3 + 'px';
    } else {
      about.style.display = 'none'
      aboutP.style.display = 'none'
    }
    //Project Titles

    let rectTitle = moon2.getBoundingClientRect();
    let rectTitle2 = viking2.getBoundingClientRect();

    let rectTitle3 = vino3.getBoundingClientRect();
    let rectTitle6 = vino.getBoundingClientRect();

    let rectTitle4 = tesla2.getBoundingClientRect();
    let rectTitle5 = tesla3.getBoundingClientRect();



    if (rectTitle.top < rect2.top && rectTitle.bottom > rect2.top) {
      leftSide.style.display = 'block'
      leftSide.style.top = '100px'
      leftSide.style.position = 'fixed'
    } else {
      leftSide.style.display = 'none'
    }

    if (rectTitle2.top < rect2.top && rectTitle2.bottom > rect2.top) {
      leftSide2.style.display = 'block'
      leftSide2.style.top = '100px'
      leftSide2.style.position = 'fixed'
    } else {
      leftSide2.style.display = 'none'
    }

    if (rectTitle3.top < rect2.top && rectTitle6.bottom > rect2.top) {
      leftSide3.style.display = 'block'
      leftSide3.style.top = '100px'
      leftSide3.style.position = 'fixed'
    } else {
      leftSide3.style.display = 'none'
    }

    if (rectTitle5.top < rect2.top && rectTitle4.top > rect2.top) {
      leftSide4.style.display = 'block'
      leftSide4.style.top = '100px'
      leftSide4.style.position = 'fixed'
    } else {
      leftSide4.style.display = 'none'
    }

    //Projects Section

    moon2.style.marginTop = value * -0.3 + 'px'
    moon3.style.scrollMarginTop = value * -0.4 + 'px'

    viking2.style.marginTop = value * -0.3 + 'px'
    viking3.style.marginTop = value * -0.2 + 'px'
    viking4.style.marginTop = value * -0.1 + 'px'

    vino.style.marginTop = value * -0.2 + 'px'
    vino2.style.marginTop = value * -0.1 + 'px'
    vino3.style.marginTop = value * -0.3 + 'px'

    tesla.style.marginTop = value * -0.1 + 'px'
    tesla2.style.marginBottom = value * -0.1 + 'px'
    tesla3.style.marginTop = value * -0.1 + 'px'
    tesla4.style.marginBottom = value * -0.1 + 'px'

    //Skills Section

    let rect3 = footer.getBoundingClientRect();
    let rect4 = skill9.getBoundingClientRect();

    if (rect4.bottom < rect3.bottom) {
      skill.style.transform = 'rotate(90deg)';
    } else {
      skill.style.transform = 'rotate(0deg)';
    }
  })
}

parallax();

export default parallax();

