let theme = () => {
  let light = document.getElementById('light')
  let body = document.getElementById('body')
  let dark = document.getElementById('dark')

  light.addEventListener('click', function () {
    body.classList.add("light");
    body.classList.remove('dark');
    light.style.display = 'none'
    dark.style.display = 'block'

  })

  dark.addEventListener('click', function () {
    body.classList.add("dark");
    body.classList.remove('light')
    light.style.display = 'block'
    dark.style.display = 'none'

  })
}

theme();

export default theme();
